<template>
  <el-row>
    <el-col class="float-menu">
    <!-- 热门软件  -->
      <el-col class="bg">
          <!-- <el-col class="item">{{ item1.name }} <img class="item-icon" src="@/img/home/right.svg" ></el-col> -->
          <el-col class="item">热门软件</el-col>
          <el-col class="item-list">
              <el-tooltip effect="dark" :content="popularSoftware[0].softwareGoodsName" placement="left-start">
                <a class="item-list-left" href="javascript:;"  @click="openProductDetail(popularSoftware[0].softwareGoodsId)">{{popularSoftware[0].softwareGoodsName}}</a>
              </el-tooltip>
              <el-tooltip effect="dark" :content="popularSoftware[1].softwareGoodsName" placement="right-start">
                <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularSoftware[1].softwareGoodsId)">{{popularSoftware[1].softwareGoodsName}}</a>
              </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" :content="popularSoftware[2].softwareGoodsName" placement="left-start">
              <a class="item-list-left" href="javascript:;"  @click="openProductDetail(popularSoftware[2].softwareGoodsId)">{{popularSoftware[2].softwareGoodsName}}</a>
            </el-tooltip>
            <el-tooltip effect="dark" :content="popularSoftware[3].softwareGoodsName" placement="right-start">
              <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularSoftware[3].softwareGoodsId)">{{popularSoftware[3].softwareGoodsName}}</a>
            </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" :content="popularSoftware[4].softwareGoodsName" placement="left-start">
              <a class="item-list-left" href="javascript:;"  @click="openProductDetail(popularSoftware[4].softwareGoodsId)">{{popularSoftware[4].softwareGoodsName}}</a>
            </el-tooltip>
            <el-tooltip effect="dark" :content="popularSoftware[5].softwareGoodsName" placement="right-start">
              <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularSoftware[5].softwareGoodsId)">{{popularSoftware[5].softwareGoodsName}}</a>
            </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" :content="popularSoftware[6].softwareGoodsName" placement="left-start">
              <a class="item-list-left" href="javascript:;"  @click="openProductDetail(popularSoftware[6].softwareGoodsId)">{{popularSoftware[6].softwareGoodsName}}</a>
            </el-tooltip>
            <el-tooltip effect="dark" :content="popularSoftware[7].softwareGoodsName" placement="right-start">
              <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularSoftware[7].softwareGoodsId)">{{popularSoftware[7].softwareGoodsName}}</a>
            </el-tooltip>
          </el-col>
        <el-col class="item-list item-margin">
          <el-tooltip effect="dark" :content="popularSoftware[8].softwareGoodsName" placement="left-start">
            <a class="item-list-left" href="javascript:;"  @click="openProductDetail(popularSoftware[8].softwareGoodsId)">{{popularSoftware[8].softwareGoodsName}}</a>
          </el-tooltip>
          <el-tooltip effect="dark" :content="popularSoftware[9].softwareGoodsName" placement="right-start">
            <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularSoftware[9].softwareGoodsId)">{{popularSoftware[9].softwareGoodsName}}</a>
          </el-tooltip>
        </el-col>
      </el-col>
    <!--   热门硬件     -->
      <el-col class="bg">
          <el-col class="item">热门硬件</el-col>
          <el-col class="item-list">
              <el-tooltip effect="dark" :content="popularHardware[0].softwareGoodsName" placement="left-start">
                <a class="item-list-left" href="javascript:;" @click="openProductDetail(popularHardware[0].softwareGoodsId)">{{popularHardware[0].softwareGoodsName}}</a>
              </el-tooltip>
              <el-tooltip effect="dark" :content="popularHardware[1].softwareGoodsName" placement="right-start">
                <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularHardware[1].softwareGoodsId)">{{popularHardware[1].softwareGoodsName}}</a>
              </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" :content="popularHardware[2].softwareGoodsName" placement="left-start">
              <a class="item-list-left" href="javascript:;" @click="openProductDetail(popularHardware[2].softwareGoodsId)">{{popularHardware[2].softwareGoodsName}}</a>
            </el-tooltip>
            <el-tooltip effect="dark" :content="popularHardware[3].softwareGoodsName" placement="right-start">
              <a class="item-list-right" href="javascript:;" @click="openProductDetail(popularHardware[3].softwareGoodsId)">{{popularHardware[3].softwareGoodsName}}</a>
            </el-tooltip>
          </el-col>
      </el-col>
    <!--   弹性计算     -->
      <el-col class="bg">
          <el-col class="item">云市场</el-col>
          <el-col class="item-list">
              <el-tooltip effect="dark" content="GitHub Copilot全球加速平台" placement="left-start">
                <a class="item-list-left" href="javascript:;"
                   @click="goPageParam('/cloudMarket', 'adadada9-f9b8-482c-b378-fc1e0a9b5617')">
                  GitHub Copilot全球加速平台
                </a>
              </el-tooltip>
              <el-tooltip effect="dark" content="e-gpt企业智能助手" placement="right-start">
                <a class="item-list-right" href="javascript:;"
                  @click="goPageParam('/cloudMarket', '8b292b68-d49a-4fbc-a7c7-0d7a5602ebc8')">
                  e-gpt企业智能助手
                </a>
              </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" content="AD域管理平台" placement="left-start">
              <a class="item-list-left" href="javascript:;"
                 @click="goPageParam('/cloudMarket', 'a3d738f7-e4fe-4732-a2ea-44449a609442')">
                AD域管理平台
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" content="云文档管理系统" placement="right-start">
              <a class="item-list-right" href="javascript:;"
                 @click="goPageParam('/cloudMarket', '699357f4-55fb-4f81-ab42-b6b0e49b20e5')">
                云文档管理系统
              </a>
            </el-tooltip>
          </el-col>
          <el-col class="item-list item-margin">
            <el-tooltip effect="dark" content="多云管理平台" placement="left-start">
              <a class="item-list-left" href="javascript:;"
                 @click="goPageParam('/cloudMarket', '45cc8016-36b5-4088-a5ee-317f1b00a057')">
                多云管理平台
              </a>
            </el-tooltip>
            <el-tooltip effect="dark" content="多云费用管理平台" placement="right-start">
              <a class="item-list-right" href="javascript:;"
                 @click="goPageParam('/cloudMarket', 'bd41406b-d0f2-4a19-bb45-768c3b47cf7e')">
                多云费用管理平台
              </a>
            </el-tooltip>
          </el-col>
      </el-col>
    <!--   存储服务     -->
<!--      <el-col class="bg">-->
<!--          <el-col class="item">存储服务</el-col>-->
<!--          <el-col class="item-list">-->
<!--              <a class="item-list-left" href="javascript:;"-->
<!--                 @click="redirectUrl('https://storage.huaweicloud.com/obs/?region=cn-north-4&locale=zh-cn#/obs/buy')">-->
<!--                对象存储服务-->
<!--              </a>-->
<!--              <a class="item-list-right" href="javascript:;"-->
<!--                @click="redirectUrl('https://console.huaweicloud.com/cbr/?locale=zh-cn&region=cn-north-4#/cbr/createVault/csbs')">-->
<!--                云备份-->
<!--              </a>-->
<!--          </el-col>-->
<!--      </el-col>-->
<!--    &lt;!&ndash;   网络与CDN     &ndash;&gt;-->
<!--      <el-col class="bg">-->
<!--          <el-col class="item">网络与CDN</el-col>-->
<!--          <el-col class="item-list">-->
<!--             <a class="item-list-left" href="javascript:;"-->
<!--                 @click="redirectUrl('https://account.huaweicloud.com/usercenter/?region=cn-north-4#/commonbuy/cdnCloud')">-->
<!--                内容分发网络-->
<!--              </a>-->
<!--              <a class="item-list-right" href="javascript:;"-->
<!--                @click="redirectUrl('https://console.huaweicloud.com/vpc/?region=cn-north-4#/vpcs')">-->
<!--                虚拟私有云-->
<!--              </a>-->
<!--          </el-col>-->
<!--      </el-col>-->
<!--    &lt;!&ndash;   数据库     &ndash;&gt;-->
<!--      <el-col class="bg">-->
<!--          <el-col class="item">数据库</el-col>-->
<!--          <el-col class="item-list">-->
<!--              <a class="item-list-left" href="javascript:;"-->
<!--                 @click="redirectUrl('https://console.huaweicloud.com/rds/?region=cn-north-4&Engine=mysql#/rds/createIns/')">-->
<!--                云库MySQL-->
<!--              </a>-->
<!--              <a class="item-list-right" href="javascript:;"-->
<!--                @click="redirectUrl('https://console.huaweicloud.com/rds/?region=cn-north-4&engine=sqlserver#/rds/createIns/')">-->
<!--                云库SQLServer-->
<!--              </a>-->
<!--          </el-col>-->
<!--      </el-col>-->
    </el-col>
    <el-col class="banner">
      <el-carousel arrow="never" ref="carousel"  trigger="click" :autoplay="false">
        <div class="left-arrow arrow" @click="arrowClick('prev')" :class="{hide: arrowFlagHide==true}">
          <img src="@/img/common/ic_arrow_left.svg"/>
        </div>

        <el-carousel-item v-for="(item,index) in bannerList" :key="index">
          <el-col class="banner-bg" :style='{background: "url(" + item.img +") no-repeat top center"}'>
            <el-col :class="item.align">
<!--              <el-col class="flow-menu">-->
<!--                <el-col  v-for=" item1 in menuList" :key="item1.id">-->
<!--                    <el-col class="item">{{ item1.name }} <img class="item-icon" src="@/img/home/right.svg" ></el-col>-->
<!--                </el-col>-->
<!--              </el-col>-->
              <el-col class="flow-menu-description">
                <img :src="description[index].logo" class="logo">
                <div class="company">{{ description[index].companyName }}</div>
                <div class="content">{{ description[index].content }}</div>
                <el-button class="button" @click="openProductDetail(description[index].productId)">立即抢购</el-button>
                <img :src="description[index].img" class="img">
              </el-col>
              <el-col class="flow-menu-img">
                <img :src="description[index].img" class="flow-menu-img">
              </el-col>

              <el-col class="written-words">
                <el-col class="title" :class="item.imgNum + ' ' + item.color">{{item.writtenWords.title}}</el-col>
                <el-col class="describe" :class="item.color">{{item.writtenWords.describe}}</el-col>
              </el-col>
            </el-col>
          </el-col>
        </el-carousel-item>
        <div class="right-arrow arrow" @click="arrowClick('next')" :class="{hide: arrowFlagHide==true}">
          <img src="@/img/common/ic_arrow_right.svg"/>
        </div>
      </el-carousel>
    </el-col>
  </el-row>
</template>

<script>
import BuildingSolution from "../../views/solution/buildingSolution";
import MedicalSolution from "../../views/solution/medicalSolution";
export default {
  name: "banner-components",
  components: {MedicalSolution, BuildingSolution},
  props: {
    bannerList: {
      type: Array,
      default() {
        return []
      }
    },
    popularSoftware: {
      type: Array,
      default() {
        return []
      }
    },
    popularHardware: {
      type: Array,
      default() {
        return []
      }
    },
    // menuList: {
    //   type: Array,
    //   default() {
    //     return [
    //       {
    //         id: 1,
    //         name: '热门软件'
    //       },
    //       {
    //         id: 2,
    //         name: '存储服务'
    //       },
    //       {
    //         id: 3,
    //         name: '弹性计算'
    //       },
    //       {
    //         id: 4,
    //         name: '弹性计算'
    //       },
    //       {
    //         id: 5,
    //         name: '弹性计算'
    //       },
    //       {
    //         id: 6,
    //         name: '弹性计算'
    //       },
    //     ];
    //   }

    // },
    // 是否显示下一张banner按钮
    'arrowFlagHide': {
      type: Boolean
    }
  },
  data() {
    return{
      description: [
          {
          logo: require("@/img/home/adobeLogo.svg"),
          companyName: 'Adobe illustration',
          content: '数以百万计的设计师和艺术家使用行业标准的矢量图形软件来创建从华丽的Web和移动图形到徽标，图标，书籍插图，产品包装和广告牌的所有内容。',
          img: require("@/img/home/MacbookFrontOpenSilver.png"),
          productId: '258d6e72-8250-457e-8d43-c41d45839b1e'
        },
        {
          logo: require("@/img/home/adobeLogo.svg"),
          companyName: 'Adobe Photoshop for teams',
          content: '从照片编辑和合成到数字绘画，动画和图形设计，几乎每个创意项目的核心都是世界上最好的影像和图形设计软件。',
          img: require("@/img/home/MacbookFrontOpenSilver 2.png"),
          productId: '0be506bf-a339-4f4d-a283-25f440e95ee5'
        },
        {
          // logo: require("@/img/home/adobeLogo.svg"),
          logo: '',
          companyName: 'Surface Go',
          content: '选择轻盈的Surface笔记本电脑，充分利用每一天',
          img: require("@/img/home/Surface.png"),
          productId: '4baedb30-5517-48fe-a269-055f917c973b'
        }
        ]
    }
  },
  methods: {
    arrowClick(val) {
      if(val == 'prev') {
        this.$refs.carousel.prev();
        return;
      }
      this.$refs.carousel.next();
    },
    // 重定向页面
    redirectUrl(url){
      window.open("https://auth.huaweicloud.com/authui/saml/login?xAccountType=EDENSOFT_2013_IDP&isFirstLogin=false&service=" + encodeURIComponent(url));
    },
    // 跳转到产品详情页
    openProductDetail(goodsId){
      console.log(goodsId)
      this.$router.push({path: `/ProductDescription`,query:{productId: goodsId}});
    },
    goPage(pagePath) {
      this.$router.push({path: pagePath})
    },
    goPageParam(pagePath, params) {
      this.$router.push({path: pagePath, query:{productId: params, orderType: 1}})
    }
  }
}
</script>

<style lang="less" scoped>

//@media screen and (max-width: 1300px){
//  .banner .banner-bg .flow-menu-description {
//    margin: 0 0 0 250px !important;
//    .img {
//      display: none;
//    }
//  }
//}

.float-menu {
  z-index: 9999999;
  opacity: 0.7;
  position: absolute;
  width: 220px;
  height: 490px;
  background: #333333;
  top: 18px;
  left: 18%;
  .bg:hover {
    // background: #9ea8a8;
    //background: #9ea8a857;
  }
  .item-margin {
    margin-top: 16px;
  }
  .item {
    font-size: 14px;
    color: #FFFFFF;
    height: 21px;
    line-height: 21px;
    margin: 20px 0px 16px 21px;
  }
  .item-list {
    font-size: 12px;
    margin-left: 21px;
    .item-list-left {
      padding-right: 14px;
      box-sizing: border-box;
      text-decoration: none;
      color: #FFFFFF;
      display: inline-block;
      max-width: 100%;
      width: 80px;
      border-right: 1px solid rgb(210, 210, 210);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.7;
    }
    .item-list-left:hover {
      color: #FFFFFF;
      font-weight: bold;
      // color: #1B1B1B;
    }
    .item-list-right {
      padding-left: 14px;
      box-sizing: border-box;
      text-decoration: none;
      color: #FFFFFF;
      display: inline-block;
      max-width: 100%;
      width: 120px;
      // border-right: 1px solid rgb(210, 210, 210);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      opacity: 0.7;
    }
    .item-list-right:hover {
      color: #FFFFFF;
      font-weight: bold;
      // color: #1B1B1B;
    }
  }
  .item-icon {
    height: 17px;
    width: 10px;
    margin: -3px;
    margin-left: 83px;
  }
}
.banner {
  .banner-bg {
    height: 523px;
    width: 100%;
    .center {
      width: 840px;
      float: unset;
      margin: auto;
      text-align: center;
      .title {
        font-size: 56px;
        font-weight: bold;
        line-height: 22px;
      }

      .content {
        font-size: 36px;
        font-weight: 400;
        line-height: 48px;
        width: 840px;
        margin-top: 33px;
      }

      .describe {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        padding: 0 123px;
        margin-top: 15px;
      }
      .more-img {
        margin-top: 120px;
      }
    }
    .left {
      width: 1200px;
      margin: auto;
      float: unset;
      .more-img {
        margin-top: 146px;
      }
      .single-img {
        margin-top: 146px;
      }
      .title {
        font-size: 40px;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 22px;
      }
      .content {
        font-size: 24px;
        font-weight: 400;
        line-height: 38px;
        width: 538px;
      }
      .describe {
        font-size: 20px;
        font-weight: 400;
        line-height: 22px;
        margin-top: 42px;
      }
    }
    .flow-menu {
      width: 207px;
      height: 474px;
      //background: #f30000;
      //opacity: 0.8;
      margin: 26px 0px 23px 13px;
      .item {
          font-size: 16px;
          color: #FFFFFF;
          height: 21px;
          line-height: 21px;
          margin: 25px 0px 21px 25px ;
      }
      .item-icon {
        height: 17px;
        width: 10px;
        margin: -3px;
        margin-left: 83px;
      }
    }
    .flow-menu-description {
      width: 500px;
      height: 523px;
      margin: 0px 0px 0px 400px;
      .logo {
        width: 56px;
        height: 16px;
        margin-top: 159px;
      }
      .company {
        //width: 255px;
        height: 37px;
        font-size: 28px;
        font-weight: bold;
        color: #FFFFFF;
        line-height: 37px;
        margin-top: 21px;
      }
      .content {
        width: 340px;
        height: 63px;
        font-size: 14px;
        color: #FFFFFF;
        line-height: 21px;
        margin-top: 21px;
      }
      /deep/ .el-button {
        height: 27px;
        background: #262628;
        border-radius: 14px;
        border-color: #262628;
        color: #FFFFFF;
        font-size: 14px;
        line-height: 3px;
      }
      .button {
        margin-top: 21px;
      }
      .img {
        width: 413px;
        height: 244px;
        margin-left: 380px;
        margin-top: -218px;
      }
    }
    .center-left {
      width: 1200px;
      margin: auto;
      float: unset;
      .written-words {
        width: 537px;
        margin-top: 110px;
      }
      .title {
        font-size: 36px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 34px;
        margin-bottom: 19px;
        text-align: right;
      }
      .support-content-list {
        font-size: 18px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        text-align: right;
        margin-bottom: 10px;
      }
    }

    .black-color {
      color: #333333;
    }

    .white-font {
      color: #FFFFFF;
    }
    .content-describe {
      color: #555555;
    }
  }

  .arrow:hover {
    cursor: pointer;
  }

  .arrow {
    width: 47px;
    height: 47px;
    display: inline-block;
    z-index: 999;
    position: relative;
  }

  .right-arrow {
    right: 108px;
    position: absolute;
    top: 202px;
  }

  .left-arrow {
    top: 202px;
    left: 108px;
  }

  /deep/ .el-carousel__container {
    height: 523px;
  }

  .hide {
    display: none;
  }
}

</style>
