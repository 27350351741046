<template>
    <!-- 房地产建筑行业 -->
    <div>
        <el-row>
            <el-col :span="24">
                <img class="medical-bg" src="../../img/solution/buildingSolution/building_bg.png">
            </el-col>
            <el-col :span="24" class="solution-title">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">房地产</el-col>
                <el-col :span="17" class="center-el-col"><div class="solution-line"></div></el-col>
                <el-col :span="14" class="center-el-col">
                    <p class="text-center" style="color: #666666;">某物业管理有限公司创立于1990年，是中国首批一级物业管理资质企业，业务范围辐射华南、华东、华北、华中、西南等区域，业务类型涵盖商业综合体、政府办公、写字楼、工业区、学校、医院、住宅和安居工程等，全国成立30+分公司，管理面积超过3000万平方米。</p>
                </el-col>
                <el-col :span="14" class="center-el-col">
                    <div class="title-end">
                        <div class="solution-line2"></div>
                        <div>客户背景</div>
                    </div>
                </el-col>
            </el-col>
            <el-col :span="24" class="solution-pain">
                <el-col :span="4" :sm="10" :xs="10" class="medicalSolution">行业痛点</el-col>
                <div class="pain-cards">
                    <div class="pain-card">
                        <div><img src="../../img/solution/buildingSolution/icon1.png"></div>
                        <div class="text-center">传统文件管理方式效率低</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">资质文件、合同资料等都以大文件的形式存在，文件往来频繁，固有的传送方式中，传输速度慢。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/buildingSolution/icon2.png"></div>
                        <div class="text-center">文件版本管理混乱</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">文件修改多次，版本混乱，修改痕迹不能保留，无法确定哪个是新版本。</div>
                    </div>
                    <div class="pain-card">
                        <div><img src="../../img/solution/buildingSolution/icon3.png"></div>
                        <div class="text-center">协同效率低</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">办公地点不固定，项目组之间多人各自分工，信息交流不便，无法完成协同办公。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/buildingSolution/icon4.png"></div>
                        <div class="text-center">文件查找困难</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">数量庞大的文档资料，无法快速进行组织、分类、归档、检索。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/buildingSolution/icon5.png"></div>
                        <div class="text-center">文档保密机制缺乏管控</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">员工随意打印公司重要文件,无任何技术管理手段；员工可以不经过公司相关领导审批，将内部重要文件外发给客户；员工出差时无法对承载着公司大量重要数据的笔记本监控。</div>
                    </div>
                    <div class="pain-card pain-card1">
                        <div><img src="../../img/solution/buildingSolution/icon6.png"></div>
                        <div class="text-center">文档存储安全难以保障</div>
                        <div class="pain-block"><p class="solution-blue-line"></p></div>
                        <div class="text-center pain-content">员工离职或调岗后，项目的整体研究设计方案丢失、外泄；资料存放在员工个人电脑上，电脑中毒、硬件损坏，导致文件全部丢失。</div>
                    </div>
                </div>
            </el-col>
            <el-col :span="24">
                <el-col :span="4" :sm="10" :xs="12" class="medicalSolution">伊登云解决方案</el-col>
                <el-col :span="15" class="center-el-col"><p class="text-center">采用伊登文档管控系统，将档案资料集中存储到文档服务器，实现分级权限管理，从而保障文档安全可控，协作方便，条理性强，行为日志有迹可查。</p></el-col>
                <el-col :span="15" class="center-el-col text-center">
                    <div class="app-info">
                        <div class="app-title">文档管理系统</div>
                        <div class="flex-wrap-layout">
                            <div class="flex-wrap-layout  app-service-left">
                                <div class="service-card"><div class="service-info">文件管理</div></div>
                                <div class="service-card"><div class="service-info">用户管理</div></div>
                                <div class="service-card"><div class="service-info">权限管理</div></div>
                                <div class="service-card"><div class="service-info">空间管理</div></div>
                                <div class="service-card"><div class="service-info">数据统计</div></div>
                                <div class="service-card"><div class="service-info">登录管理</div></div>
                            </div>
                            <div class="flex-wrap-layout  app-service-right">
                                <div class="service-card"><div class="service-info">全文搜索</div></div>
                                <div class="service-card"><div class="service-info">分享管理</div></div>
                                <div class="service-card"><div class="service-info">在线预览</div></div>
                                <div class="service-card"><div class="service-info">在线编辑</div></div>
                                <div class="service-card"><div class="service-info">版本控制</div></div>
                                <div class="service-card"><div class="service-info">流程审核</div></div>
                            </div>
                        </div>
                        <div class="flex-wrap-layout app-service">
                            <div class="service-card"><div class="service-info2">ASP.net</div></div>
                            <div class="service-card"><div class="service-info2">IIS</div></div>
                        </div>
                        <div class="flex-wrap-layout app-service">
                            <div class="service-card"><div class="service-info2">Windows Server</div></div>
                            <div class="service-card"><div class="service-info2">Miscrosoft SQL Server</div></div>
                            <div class="service-card"><div class="service-info2">Office Online Server</div></div>
                        </div>
                    </div>
                </el-col>
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    name: "buildingSolution",
    data() {
        return {

        }
    },
    mounted() {

    },
    methods: {

    }
}
</script>

<style lang="less" scoped>
    * {
        font-size: 16px
    }
    .medical-bg {
        width: 100%;
    }

    .center-el-col {
        margin: auto;
        float: unset;
    }

    .solution-line {
        border: 1px solid #E5E5E5;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

    .solution-line2 {
        border: 1px solid #666666;
        width: 2rem;
        margin-right: 1rem;
        margin-top: 0.7rem;
        height: 0px;
    }

    .solution-blue-line {
        width: 48px;
        height: 6px;
        background: #2974E4;
    }

    .medicalSolution {
        margin: auto;
        float: unset;
        font-size: 40px;
        font-weight: 400;
        line-height: 45px;
        color: #3764D7;
        text-align: center;
        width: auto;
    }

    .solution-title {
        padding: 3rem 0px 1.5rem 0px;
    }

    .title-end {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
    }

    .solution-pain {
        margin-top: 2rem;
        margin-bottom: 5rem;
    }

    .pain-block {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .pain-cards {
        display: flex;
        flex-wrap: wrap;
        margin-top: 2.5rem;
        width: 100%;
        max-width: 1200px;
        margin: 2rem auto;
    }

    .pain-card {
        max-width: 23rem;
        text-align: center;
        padding: 0.75rem;
        border-right: 1px solid #dee2e6;
        border-bottom: 1px solid #dee2e6;
        //border: 1px solid #dee2e6;
    }

    .pain-card1 {
        border-bottom: 0px;
    }

    .pain-content {
        font-size: 16px;
        font-weight: 400;
        color: #999999;
        line-height: 25px;
    }


    .eden-solution-title {
        color: #333333;
        font-size: 18px;
        font-weight: bold
    }

    .eden-solution-content {
        color: #333333;
        font-size: 14px;
    }
    .app-title {
        background: #2974E4;
        width: 100%;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
    }

    .flex-wrap-layout {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;

    }

    .service-card {
        margin-top: 3%;
        padding-left: 10px;
        padding-right: 10px;
        flex-grow: 1;
        flex-basis: 30%;
    }

    .app-service{
        margin-left: -10px;
        margin-right: -10px;
    }

    .app-service-left {
        margin-left: -10px;
    }

    .app-service-right {
        margin-right: -10px;
    }

    .service-info {
        background: #2974E4;
        opacity: 1;
        border-radius: 1px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-weight: 400;
        color: #FFFFFF;
        width: 100%;
    }

    .service-info2 {
        width: 100%;
        height: 40px;
        line-height: 40px;
        border: 1px solid #E5E5E5;
        opacity: 1;
        border-radius: 1px;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
    }

    .app-info {
        width: 100%;
        box-shadow: 0px 4px 16px rgba(52,52,78,0.16);
        opacity: 1;
        padding: 2% 2%;
        margin-bottom: 2%;
    }

    @media screen and (min-width: 1200px){
        .pain-cards .pain-card:nth-child(3n) {
            border-right: 0px;
        }
    }

    @media screen and (max-width: 1200px){
        .pain-cards {
            max-width: 800px;
        }

        .pain-card1 {
            border-bottom: 1px solid #dee2e6;
        }

        .pain-cards .pain-card:nth-child(2n) {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(5) {
            border-bottom: 0px;
        }

        .pain-cards .pain-card:nth-child(6) {
            border-bottom: 0px;
        }
    }

    @media screen and (max-width: 800px){
        .pain-cards {
            max-width: 400px;
        }

        .pain-card {
            border-right: 0px;
        }

        .pain-cards .pain-card:nth-child(5) {
            border-bottom: 1px solid #dee2e6;
        }
    }

    @media screen and (max-width: 987px){
        .app-service-left {
            margin-left: -10px;
            margin-right: -10px;
        }

        .app-service-right {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    @media screen and (max-width: 1581px){
        .app-service-left {
            margin-left: -10px;
            margin-right: -10px;
        }

        .app-service-right {
            margin-left: -10px;
            margin-right: -10px;
        }
    }


</style>